@import url('https://fonts.googleapis.com/css2?family=Inconsolata:wght@300&display=swap');

.content {
  font-family: 'Inconsolata', monospace;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}